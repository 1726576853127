<tds-form-field [hasError]="showError">
  <tds-form-field-label *ngIf="label"
  >{{ label }}
    <app-tooltip-info *ngIf="info" [message]="infoMessage" class="mx-1" size="xs"></app-tooltip-info>
  </tds-form-field-label>
  <input
    (blur)="handleBlur($event)"
    (input)="updateValue($event)"
    [disabled]="disabled"
    [id]="id"
    [placeholder]="placeholder"
    [required]="required"
    [type]="type"
    [value]="value"
    data-hj-allow
    tds-form-field-item
  />
  <tds-form-field-suffix *ngIf="showError">
    <tds-icon isFilled name="error" size="sm" variant="danger"></tds-icon>
  </tds-form-field-suffix>
</tds-form-field>
