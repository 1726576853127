import {ActionReducerMap} from '@ngrx/store';
import {ChatbotState, chatbotReducer} from '../components/chatbot/chatbot-state';
import {
  RiskAssessmentCheckState,
  riskAssessmentCheckReducer
} from '../components/risk-assessment-check/risk-assessment-state';


export interface AppState {
  chatbot: ChatbotState;
  riskAssessment: RiskAssessmentCheckState;
}

export const appReducer: ActionReducerMap<AppState> = {
  chatbot: chatbotReducer,
  riskAssessment: riskAssessmentCheckReducer
};
