import {inject} from '@angular/core';
import {HttpEvent, HttpHandlerFn, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable, from} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {switchMap} from 'rxjs/operators';

export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const auth = inject(AuthenticationService);
  if (req.url.includes('assets/i18n')) {
    return next(req);
  }
  const tokenPromise = auth.getAccessToken();
  return from(tokenPromise)
    .pipe(
      switchMap(token => {
        if (token) {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`
          });
          const newReq = req.clone({headers});
          return next(newReq);
        }
        return next(req);
      })
    );
};
