import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicInputComponent } from '../shared/Input/dynamic-input/dynamic-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipInfoComponent } from '../shared/tooltip/tooltip-info/tooltip-info.component';
import { RiskAssessmentCheckService } from './risk-assessment-check.service';
import {
  CheckRiskAssessment,
  ControlMeasure,
  GeneratedRiskAssessment,
  Hazard,
  RelatedRex,
  Source
} from '@types';
import { FileUploadService } from './file-upload.service';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { DynamicTextareaComponent } from '../shared/Input/dynamic-textarea/dynamic-textarea.component';
import { DynamicInputBadgeComponent } from '../shared/Input/dynamic-input-badge/dynamic-input-badge.component';
import { UploadPdfModalComponent } from './upload-pdf-modal/upload-pdf-modal.component';
import { RiskAssessmentRexService } from './risk-assessment-rex.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app-state';
import {
  resetRiskAssessmentCheckState,
  selectCheckRiskAssessment,
  selectComplementaryControlMeasures,
  selectComplementaryHazards,
  selectControlMeasuresSources,
  selectFile,
  selectFileStatus,
  selectHazardsSources,
  selectRelatedRex,
  selectRiskAssessmentCheckRan,
  setCheckRiskAssessment,
  setComplementaryControlMeasures,
  setComplementaryHazards,
  setControlMeasuresSources,
  setFile,
  setFileStatus,
  setHazardsSources,
  setRelatedRex,
  setRiskAssessmentCheckRan,
  updateControlMeasure,
  updateHazard
} from './risk-assessment-state';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ButtonComponent,
  FormFieldComponent,
  FormFieldLabelDirective,
  IconButtonComponent,
  IconComponent,
  IconVariant,
  LayerComponent,
  LinkComponent,
  TagComponent,
  TypographyComponent
} from '@totalenergiescode/tds-angular';

@Component({
  selector: 'app-risk-assessment-check',
  standalone: true,
  imports: [
    CommonModule,
    DynamicInputComponent,
    FormsModule,
    TooltipInfoComponent,
    ConfirmationModalComponent,
    DynamicTextareaComponent,
    DynamicInputBadgeComponent,
    UploadPdfModalComponent,
    ReactiveFormsModule,
    TranslateModule,
    ButtonComponent,
    IconComponent,
    FormFieldComponent,
    FormFieldLabelDirective,
    TagComponent,
    IconButtonComponent,
    LinkComponent,
    TypographyComponent,
    LayerComponent
  ],
  templateUrl: './risk-assessment-check.component.html',
  styleUrl: './risk-assessment-check.component.scss'
})
export class RiskAssessmentCheckComponent implements AfterViewInit, OnInit, OnDestroy {
  checkRiskAssessment$: Observable<CheckRiskAssessment | null>;
  complementaryHazards$: Observable<Hazard[]>;
  complementaryControlMeasures$: Observable<ControlMeasure[]>;
  relatedRex$: Observable<RelatedRex[]>;
  riskAssessmentCheckRan$: Observable<boolean>;
  file$: Observable<File>;
  fileStatus$: Observable<string>;
  hazardSource$: Observable<Source[]>;
  controlMeasureSource$: Observable<Source[]>;
  checkRiskAssessmentForm: CheckRiskAssessment = {
    country: '',
    description: '',
    equipmentInvolved: '',
    usedTools: '',
    hazards: [],
    controlMeasures: [],
    workingContext: []
  };
  workingContextBadges: string[] = [];
  riskAssessmentCheckRan: boolean = false;
  displaySources: boolean = false;
  complementaryHazards: Hazard[] = [];
  hazardsSources: Source[] = [];
  complementaryControlMeasures: ControlMeasure[] = [];
  controlMeasuresSources: Source[] = [];
  loading: boolean = false;
  countries: string[] = ['France', 'Germany', 'Italy'];
  selectedCountry: string = '';
  fileName: string | null = null;
  file: File = new File([], '');
  error: string = '';
  uploadLoader: boolean = false;
  titleUploadPdfModal: string = '';
  messageUploadPdfModal: string = '';
  buttonActionTextUploadPdfModal: string = '';
  buttonCancelTextUploadPdfModal: string = '';
  oneFileUploaded: boolean = false;
  errorFileUploaded: boolean = false;
  errorFileUploadedInvalidPdf: boolean = false;
  rexLoader: boolean = false;
  relatedRex: RelatedRex[] = [];
  confirmationModalContent = {
    title: '',
    message: '',
    buttonText: '',
    action: () => {}
  };
  private destroy$ = new Subject<void>();
  @ViewChild('inputFileRef') private inputFileRef!: ElementRef;

  constructor(
    public riskAssessmentCheckService: RiskAssessmentCheckService,
    public riskAssessmentRexService: RiskAssessmentRexService,
    private cdr: ChangeDetectorRef,
    public fileUploadService: FileUploadService,
    public store: Store<AppState>,
    private renderer: Renderer2,
    private el: ElementRef,
    private translate: TranslateService
  ) {
    this.checkRiskAssessment$ = this.store.select(selectCheckRiskAssessment);
    this.complementaryHazards$ = this.store.select(selectComplementaryHazards);
    this.complementaryControlMeasures$ = this.store.select(selectComplementaryControlMeasures);
    this.relatedRex$ = this.store.select(selectRelatedRex);
    this.riskAssessmentCheckRan$ = this.store.select(selectRiskAssessmentCheckRan);
    this.file$ = this.store.select(selectFile);
    this.fileStatus$ = this.store.select(selectFileStatus);
    this.hazardSource$ = this.store.select(selectHazardsSources);
    this.controlMeasureSource$ = this.store.select(selectControlMeasuresSources);
  }

  ngOnInit(): void {
    this.updateRiskAssessmentState();
  }

  updateRiskAssessmentState() {
    this.checkRiskAssessment$.pipe(takeUntil(this.destroy$)).subscribe((checkRiskAssessment) => {
      this.checkRiskAssessmentForm = {
        country: checkRiskAssessment?.country ?? '',
        description: checkRiskAssessment?.description ?? '',
        equipmentInvolved: checkRiskAssessment?.equipmentInvolved ?? '',
        usedTools: checkRiskAssessment?.usedTools ?? '',
        hazards: Array.isArray(checkRiskAssessment?.hazards)
          ? [...new Set(checkRiskAssessment?.hazards)].join('\n')
          : (checkRiskAssessment?.hazards ?? ''),
        controlMeasures: Array.isArray(checkRiskAssessment?.controlMeasures)
          ? [...new Set(checkRiskAssessment?.controlMeasures)].join('\n')
          : (checkRiskAssessment?.controlMeasures ?? ''),
        workingContext: checkRiskAssessment?.workingContext ?? []
      };
      this.workingContextBadges = checkRiskAssessment?.workingContext ?? [];
    });
    this.complementaryHazards$.pipe(takeUntil(this.destroy$)).subscribe((complementaryHazards) => {
      this.complementaryHazards = complementaryHazards;
    });
    this.hazardSource$.pipe(takeUntil(this.destroy$)).subscribe((hazardsSources) => {
      this.hazardsSources = hazardsSources;
    });
    this.complementaryControlMeasures$
      .pipe(takeUntil(this.destroy$))
      .subscribe((complementaryControlMeasures) => {
        this.complementaryControlMeasures = complementaryControlMeasures;
      });
    this.controlMeasureSource$
      .pipe(takeUntil(this.destroy$))
      .subscribe((controlMeasuresSources) => {
        this.controlMeasuresSources = controlMeasuresSources;
      });
    this.relatedRex$.pipe(takeUntil(this.destroy$)).subscribe((relatedRex) => {
      this.relatedRex = relatedRex;
    });
    this.riskAssessmentCheckRan$
      .pipe(takeUntil(this.destroy$))
      .subscribe((riskAssessmentCheckRan) => {
        this.riskAssessmentCheckRan = riskAssessmentCheckRan;
      });
    this.file$.pipe(takeUntil(this.destroy$)).subscribe((file) => {
      this.file = file;
      this.formatFilename(file);
    });
    this.fileStatus$.pipe(takeUntil(this.destroy$)).subscribe((fileStatus) => {
      if (fileStatus === 'error file') {
        this.errorFileUploaded = true;
      }
      if (fileStatus === 'new file') {
        this.oneFileUploaded = true;
      }
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.updateWorkingContextState();
  }

  updateWorkingContextState() {
    const inputElement = this.el.nativeElement.querySelector('#working-context-id');
    this.renderer.listen(inputElement, 'keydown', (event) => {
      if (event.key === 'Enter') {
        this.checkRiskAssessmentForm.workingContext = this.workingContextBadges;
        const checkRiskAssessment = { ...this.checkRiskAssessmentForm };
        this.store.dispatch(setCheckRiskAssessment({ checkRiskAssessment: checkRiskAssessment }));
      }
    });
  }

  updateInputsState(event: Event, field: string) {
    const checkRiskAssessment = {
      ...this.checkRiskAssessmentForm,
      [field]: (event.target as HTMLInputElement).value
    };
    this.store.dispatch(setCheckRiskAssessment({ checkRiskAssessment: checkRiskAssessment }));
  }

  runRiskAssessmentCheck() {
    if (window.hj) {
      window.hj('tagRecording', ['RiskAssessmentCheckClique']);
    }
    this.closeError();
    this.loading = true;
    const checkRiskAssessmentRequest: CheckRiskAssessment = {
      country: this.checkRiskAssessmentForm.country,
      description: this.checkRiskAssessmentForm.description,
      equipmentInvolved: this.checkRiskAssessmentForm.equipmentInvolved,
      usedTools: this.checkRiskAssessmentForm.usedTools,
      hazards:
        typeof this.checkRiskAssessmentForm.hazards === 'string'
          ? this.checkRiskAssessmentForm.hazards.split('\n')
          : [],
      controlMeasures:
        typeof this.checkRiskAssessmentForm.controlMeasures === 'string'
          ? this.checkRiskAssessmentForm.controlMeasures.split('\n')
          : [],
      workingContext: this.checkRiskAssessmentForm.workingContext
    };
    this.riskAssessmentCheckService
      .runRiskAssessmentCheck(checkRiskAssessmentRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: GeneratedRiskAssessment) => {
          if (response.missingHazards === undefined) {
            this.loading = false;
            this.store.dispatch(setRiskAssessmentCheckRan({ riskAssessmentCheckRan: true }));
            return;
          }
          const complementaryHazards = response.missingHazards.map((hazard: string) => {
            return { name: hazard, checked: false };
          });
          const complementaryControlMeasures = response.missingControlMeasures.map(
            (controlMeasure: string) => {
              return { name: controlMeasure, checked: false };
            }
          );
          this.store.dispatch(setRiskAssessmentCheckRan({ riskAssessmentCheckRan: true }));
          this.loading = false;
          this.scrollToComplementariesComponent();
          this.store.dispatch(
            setComplementaryHazards({ complementaryHazards: complementaryHazards })
          );
          this.store.dispatch(
            setComplementaryControlMeasures({
              complementaryControlMeasures: complementaryControlMeasures
            })
          );
          this.store.dispatch(
            setHazardsSources({ hazardsSources: response.missingHazardsSources })
          );
          this.store.dispatch(
            setControlMeasuresSources({
              controlMeasuresSources: response.missingControlMeasuresSources
            })
          );
        },
        error: (err) => {
          this.loading = false;
          if (err?.status === 422) {
            this.error = 'The Risk Assessment check is only available in English.';
          } else {
            this.error = err?.error?.message;
          }
        }
      });
  }

  getRex() {
    this.rexLoader = true;
    const checkRiskAssessmentRequest: CheckRiskAssessment = {
      country: this.selectedCountry,
      description: this.checkRiskAssessmentForm.description,
      equipmentInvolved: this.checkRiskAssessmentForm.equipmentInvolved,
      usedTools: this.checkRiskAssessmentForm.usedTools,
      hazards: [],
      controlMeasures: [],
      workingContext: this.checkRiskAssessmentForm.workingContext
    };
    this.riskAssessmentRexService
      .runRiskAssessmentRex(checkRiskAssessmentRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: RelatedRex[]) => {
          this.store.dispatch(setRelatedRex({ relatedRex: response }));
        },
        error: (err) => {
          this.error = err?.error?.message;
        },
        complete: () => {
          this.rexLoader = false;
        }
      });
  }

  uploadPdfModalAction: () => void = () => {};

  scrollToComplementariesComponent() {
    setTimeout(() => {
      document
        .getElementById('complementaries')
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  }

  editRiskAssessmentCheck() {
    this.store.dispatch(setRiskAssessmentCheckRan({ riskAssessmentCheckRan: false }));
    this.relatedRex = [];
  }

  verifyCheckButtonEnable() {
    return (
      this.checkRiskAssessmentForm.description.trim().length === 0 ||
      this.checkRiskAssessmentForm.equipmentInvolved.trim().length === 0 ||
      this.checkRiskAssessmentForm.usedTools.trim().length === 0 ||
      this.checkRiskAssessmentForm.hazards.length == 0 ||
      this.checkRiskAssessmentForm.controlMeasures.length === 0 ||
      this.checkRiskAssessmentForm.workingContext.length === 0
    );
  }

  updateConfirmationModalContent(value: string) {
    if (value === 'edit') {
      this.confirmationModalContent = {
        message: this.translate.instant('RISK_ASSESSMENT.EDIT_MODAL_MESSAGE'),
        title: this.translate.instant('RISK_ASSESSMENT.EDIT_MODAL_TITLE'),
        buttonText: this.translate.instant('RISK_ASSESSMENT.EDIT_MODAL_BUTTON_TEXT'),
        action: () => this.editRiskAssessmentCheck()
      };
    } else {
      this.confirmationModalContent = {
        message: this.translate.instant('RISK_ASSESSMENT.CLEAR_MODAL_MESSAGE'),
        title: this.translate.instant('RISK_ASSESSMENT.CLEAR_MODAL_TITLE'),
        buttonText: this.translate.instant('RISK_ASSESSMENT.CLEAR_MODAL_BUTTON_TEXT'),
        action: () => this.clearAllFields()
      };
    }
  }

  hazardCopied(hazard: Hazard) {
    if (!hazard.checked) {
      navigator.clipboard.writeText(hazard.name);
      this.store.dispatch(updateHazard({ hazard }));
    }
  }

  controlMeasureCopied(controlMeasure: ControlMeasure) {
    if (!controlMeasure.checked) {
      navigator.clipboard.writeText(controlMeasure.name);
      this.store.dispatch(updateControlMeasure({ controlMeasure }));
    }
  }

  removeBadge(badge: string) {
    const index = this.workingContextBadges.indexOf(badge);
    if (index >= 0) {
      this.workingContextBadges.splice(index, 1);
    }
  }

  clearAllFields() {
    this.clearInputFile();
    this.store.dispatch(resetRiskAssessmentCheckState());
  }

  preventDefaultFn = (event: Event) => event.preventDefault();

  getUploader() {
    if (window.hj) {
      window.hj('tagRecording', ['RiskAssessmentUploadClique']);
    }
    this.closeError();
    const uploader = document.getElementById('upload-div');
    if (!uploader) return;

    if (this.uploadLoader) return;

    if (this.oneFileUploaded) {
      this.store.dispatch(setFileStatus({ fileStatus: 'new file' }));
      this.activateUploader(
        uploader,
        this.translate.instant('RISK_ASSESSMENT.IMPORT_ANOTHER_PDF'),
        this.translate.instant('RISK_ASSESSMENT.IMPORT_ANOTHER_PDF_NOTE'),
        this.translate.instant('RISK_ASSESSMENT.IMPORT_NEW_PDF'),
        this.translate.instant('RISK_ASSESSMENT.CANCEL'),
        this.openUploader
      );
    } else if (this.errorFileUploaded) {
      this.store.dispatch(setFileStatus({ fileStatus: 'error file' }));
      this.activateUploader(
        uploader,
        this.translate.instant('RISK_ASSESSMENT.INVALID_PDF'),
        this.translate.instant('RISK_ASSESSMENT.INVALID_PDF_NOTE'),
        this.translate.instant('RISK_ASSESSMENT.IMPORT_PDF'),
        this.translate.instant('RISK_ASSESSMENT.FILL_FIELDS_MANUALLY'),
        this.openUploader
      );
      this.clearAllFields();
    } else {
      this.openUploader();
    }
  }

  openUploader() {
    const uploader = document.getElementById('upload-div');
    if (uploader) {
      uploader.removeEventListener('click', this.preventDefaultFn);
    }

    const input = document.getElementById('rac-file-upload');
    if (!input) return;
    input.click();

    if (uploader) {
      uploader.addEventListener('click', this.preventDefaultFn);
    }
  }

  clearInputFile(event?: Event) {
    if (event) event.stopPropagation();
    this.fileName = null;
    this.errorFileUploaded = false;
    this.errorFileUploadedInvalidPdf = false;
    this.oneFileUploaded = false;
    this.inputFileRef.nativeElement.value = '';
    const uploader = document.getElementById('upload-div');
    if (uploader) {
      uploader.removeAttribute('data-bs-target');
      uploader.removeAttribute('data-bs-toggle');
    }
  }

  activateUploader(
    uploader: HTMLElement,
    title: string,
    message: string,
    buttonActionText: string,
    buttonCancelText: string,
    action: () => void
  ) {
    uploader.addEventListener('click', this.preventDefaultFn);
    uploader.setAttribute('data-bs-target', '#uploadPDFModal');
    uploader.setAttribute('data-bs-toggle', 'modal');
    uploader.click();
    this.titleUploadPdfModal = title;
    this.messageUploadPdfModal = message;
    this.buttonActionTextUploadPdfModal = buttonActionText;
    this.buttonCancelTextUploadPdfModal = buttonCancelText;
    this.uploadPdfModalAction = action;
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.formatFilename(file);
      this.store.dispatch(setFile({ file }));
      this.upload(file);
    }
  }

  upload(file: File): void {
    this.closeError();
    this.uploadLoader = true;
    if (file.name.length > 0) {
      this.fileUploadService
        .uploadFile(file)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: CheckRiskAssessment) => {
            console.log(response);
            this.store.dispatch(setCheckRiskAssessment({ checkRiskAssessment: response }));
            this.store.dispatch(setFileStatus({ fileStatus: 'new file' }));
            this.uploadLoader = false;
            this.oneFileUploaded = true;
          },
          error: (error) => {
            if (error?.error?.message === 'Invalid PDF') {
              this.errorFileUploadedInvalidPdf = true;
              this.oneFileUploaded = false;
              this.store.dispatch(setFileStatus({ fileStatus: 'error file' }));
            } else {
              this.errorFileUploaded = true;
              this.oneFileUploaded = false;
            }
            this.uploadLoader = false;
            this.getUploader();
          }
        });
    }
  }

  hazardChecked(hazard: Hazard): IconVariant {
    return hazard.checked ? 'primary' : 'danger';
  }

  controlMeasureChecked(controlMeasure: ControlMeasure): IconVariant {
    return controlMeasure.checked ? 'primary' : 'danger';
  }

  closeError() {
    this.error = '';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private formatFilename(file: File) {
    if (file.name === '') {
      this.fileName = null;
      return;
    }
    if (file.name.length > 20) {
      this.fileName = file.name.substring(0, 20) + '...';
    } else {
      this.fileName = file.name;
    }
  }
}
