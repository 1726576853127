import {Component, EventEmitter, Input, Output, forwardRef} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {TooltipInfoComponent} from '../../tooltip/tooltip-info/tooltip-info.component';
import {DynamicInputValue} from '@types';
import {
  FieldErrorDirective,
  FormFieldComponent,
  FormFieldItemDirective,
  FormFieldLabelDirective,
  FormFieldSuffixDirective,
  IconComponent
} from '@totalenergiescode/tds-angular';

@Component({
  selector: 'app-dynamic-input',
  standalone: true,
  imports: [
    CommonModule,
    TooltipInfoComponent,
    FormFieldComponent,
    FormFieldItemDirective,
    FormFieldLabelDirective,
    FieldErrorDirective,
    FormFieldSuffixDirective,
    IconComponent
  ],
  templateUrl: './dynamic-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DynamicInputComponent),
      multi: true
    }
  ]
})
export class DynamicInputComponent implements ControlValueAccessor, Validator {
  @Input() type: 'text' | 'number' | 'date' | 'email' = 'text';
  @Input() label: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() errorMessage: string = 'This field is required';
  @Input() required: boolean = false;
  @Input() info: boolean = false;
  @Input() infoMessage: string = '';
  @Input() className: string = 'form-control';
  @Input() disabled: boolean = false;
  @Output() blurEvent = new EventEmitter<Event>();


  value: DynamicInputValue = null;
  isTouched: boolean = false;

  constructor() {
  }

  get showError(): boolean {
    return this.required && this.isTouched && !this.value;
  }

  onChange: (value: DynamicInputValue) => void = () => {
  };

  onTouch: () => void = () => {
  };

  writeValue(value: DynamicInputValue): void {
    this.value = value;
    this.onChange(value);
    this.onTouch();
  }

  registerOnChange(fn: (value: DynamicInputValue) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (this.required && (!value || value === '')) {
      return {required: true};
    }
    return null;
  }

  updateValue(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (this.type === 'number') {
      this.value = target.valueAsNumber;
    } else if (this.type === 'date') {
      this.value = target.valueAsDate;
    } else {
      this.value = target.value;
    }
    this.onChange(this.value);
    this.onTouch();
  }

  handleBlur(event: Event): void {
    this.isTouched = true;
    this.onTouch();
    this.blurEvent.emit(event);
  }
}
