<div class="container">
  <div class="row me-1">
    <div class="col-md-10 col-sm-12">
      <h3 class="fw-bold mt-2 mb-3">
        {{ 'RISK_ASSESSMENT.RISK_ASSESSMENT_CHECK' | translate }}
        <app-tooltip-info [message]="('RISK_ASSESSMENT.TITLE_INFO_MESSAGE' | translate)"
                          size="sm"></app-tooltip-info>
      </h3>

      <span class="mb-2"
      >{{ 'RISK_ASSESSMENT.REFINE_THE_RISK_ASSESSMENT' | translate }}</span
      >
      <div
        *ngIf="error.length > 0"
        aria-atomic="true"
        aria-live="assertive"
        class="toast error-toast align-items-center fs-5 shadow-sm show w-25 m-4"
      >
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex justify-content-center align-items-center mx-3">
            <tds-icon isFilled name="cancel" size="sm"></tds-icon>
          </div>
          <div class="toast-body">
            {{ error }}
          </div>
          <button (click)="closeError()" class="justify-content-start h-25 m-1" data-bs-dismiss="toast" label="Close"
                  size="lg"
                  tds-icon-button>
            <tds-icon name="close"></tds-icon>
          </button>
        </div>
      </div>
    </div>
    <div (click)="getUploader()" (keydown)="getUploader()" class="col-md-2 col-sm-12 upload-file" id="upload-div">
      <input
        #inputFileRef
        (change)="onFileSelected($event)"
        (keydown)="onFileSelected($event)"
        accept=".pdf"
        class="d-none"
        id="rac-file-upload"
        type="file"
      />
      <app-upload-pdf-modal (action)="uploadPdfModalAction()" (cancel)="clearInputFile($event)"
                            [buttonActionText]="buttonActionTextUploadPdfModal"
                            [buttonCancelText]="buttonCancelTextUploadPdfModal"
                            [message]="messageUploadPdfModal" [title]="titleUploadPdfModal"></app-upload-pdf-modal>
      <tds-icon *ngIf="!uploadLoader" class="mb-2" name="upload_file" variant="inherit"/>
      <div *ngIf="uploadLoader" class="spinner-border mb-3" style="color: var(--tds-color-foreground-primary)">
        <span class="visually-hidden">Loading...</span>
      </div>
      <label (click)="preventDefaultFn($event)" (keydown)="preventDefaultFn($event)" *ngIf="fileName === null"
             class="fw-bold" for="rac-file-upload">
        {{ 'RISK_ASSESSMENT.IMPORT_E_VISION_PDF' | translate }}
      </label
      >
      <label *ngIf="fileName !== null" class="fw-bold" for="rac-file-upload">{{ fileName }}</label>
    </div>
  </div>
  <div class="card w-100 my-3 p-2">
    <h5 class="m-3">{{ 'RISK_ASSESSMENT.PERMIT_TO_WORK_INPUTS' | translate }}</h5>
    <div class="card-body row">
      <div class="d-flex col-md-4 col-sm-12">
        <tds-form-field class="w-100">
          <tds-form-field-label>{{ 'RISK_ASSESSMENT.COUNTRY' | translate }}</tds-form-field-label>
          <select
            (change)="updateInputsState($event, 'country')"
            [(ngModel)]="checkRiskAssessmentForm.country"
            [disabled]="riskAssessmentCheckRan"
            data-hj-allow
            id="rac-country"
            tds-form-field-item
          >
            <option *ngFor="let country of countries" [value]="country">{{ country }}</option>
          </select>
        </tds-form-field>
      </div>
      <div class="col-md-8">
        <app-dynamic-input-badge
          (badgesChange)="workingContextBadges = $event"
          [badges]="workingContextBadges"
          [disabled]="riskAssessmentCheckRan"
          [errorMessage]="('RISK_ASSESSMENT.WORKING_CONTEXT_ERROR' | translate)"
          [infoMessage]="('RISK_ASSESSMENT.WORKING_CONTEXT_INFO_MESSAGE' | translate)"
          [placeholder]="workingContextBadges.length === 0 ? ('RISK_ASSESSMENT.WORKING_CONTEXT' | translate) : '' "
          [required]="true"
          id="working-context-id"
          label="{{ 'RISK_ASSESSMENT.WORKING_CONTEXT' | translate }}"
        >
        </app-dynamic-input-badge>
      </div>
    </div>
    <div class="card-body row">
      <div class="form-group col-md-4 col-sm-12">
        <app-dynamic-textarea
          (blurEvent)="updateInputsState($event, 'description')"
          [(ngModel)]="checkRiskAssessmentForm.description"
          [disabled]="riskAssessmentCheckRan"
          [minLength]="20"
          [required]="true"
          [rows]="15"
          class="mb-2"
          id="rac-description"
          label="{{ 'RISK_ASSESSMENT.DESCRIPTION' | translate }}"
          name="description"
          placeholder="{{ 'RISK_ASSESSMENT.DESCRIPTION' | translate }}"
        ></app-dynamic-textarea>
      </div>
      <div class="col-md-8 col-sm-12">
        <div class="row">
          <div class="col-md-6">
            <app-dynamic-input
              (blurEvent)="updateInputsState($event, 'equipmentInvolved')"
              [(ngModel)]="checkRiskAssessmentForm.equipmentInvolved"
              [disabled]="riskAssessmentCheckRan"
              [infoMessage]="('RISK_ASSESSMENT.EQUIPMENT_INVOLVED_INFO_MESSAGE' | translate)"
              [info]="true"
              [required]="true"
              class="mb-2"
              id="rac-equipment-involved"
              label="{{ 'RISK_ASSESSMENT.EQUIPMENT_INVOLVED' | translate }}"
              placeholder="{{ 'RISK_ASSESSMENT.EQUIPMENT_INVOLVED' | translate }}"
              type="text"
            >
            </app-dynamic-input>
          </div>
          <div class="col-md-6">
            <app-dynamic-input
              (blurEvent)="updateInputsState($event, 'usedTools')"
              [(ngModel)]="checkRiskAssessmentForm.usedTools"
              [disabled]="riskAssessmentCheckRan"
              [required]="true"
              class="mb-2"
              id="rac-tool-used"
              label="{{ 'RISK_ASSESSMENT.USED_TOOLS' | translate }}"
              placeholder="{{ 'RISK_ASSESSMENT.USED_TOOLS' | translate }}"
              type="text"
            >
            </app-dynamic-input>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6 col-sm-12">
            <app-dynamic-textarea
              (blurEvent)="updateInputsState($event, 'hazards')"
              [(ngModel)]="checkRiskAssessmentForm.hazards"
              [disabled]="riskAssessmentCheckRan"
              [required]="true"
              [rows]="11"
              class="mb-2"
              classNameContainer="mt-3"
              formText="{{ 'RISK_ASSESSMENT.TEXT_AREA_NOTE' | translate }}"
              id="rac-risk-assessment"
              label="{{ 'RISK_ASSESSMENT.HAZARDS' | translate }}"
              placeholder="{{ 'RISK_ASSESSMENT.HAZARDS' | translate }}"
            ></app-dynamic-textarea>
          </div>
          <div class="col-md-6 col-sm-12">
            <app-dynamic-textarea
              (blurEvent)="updateInputsState($event, 'controlMeasures')"
              [(ngModel)]="checkRiskAssessmentForm.controlMeasures"
              [disabled]="riskAssessmentCheckRan"
              [required]="true"
              [rows]="11"
              class="mb-2"
              classNameContainer="mt-3"
              formText="{{ 'RISK_ASSESSMENT.TEXT_AREA_NOTE' | translate }}"
              id="rac-control-measure"
              label="{{ 'RISK_ASSESSMENT.CONTROL_MEASURES' | translate }}"
              placeholder="{{ 'RISK_ASSESSMENT.CONTROL_MEASURES' | translate }}"
            ></app-dynamic-textarea>
          </div>
        </div>
      </div>
      <div
        style="display: flex; gap: var(--tds-size-spacing-150); justify-content: end; margin: var(--tds-size-spacing-150) 0">
        <div *ngIf="loading" class="spinner-border mx-3" style="color: var(--tds-color-foreground-primary)">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          (click)="updateConfirmationModalContent('clear')"
          [disabled]="riskAssessmentCheckRan || loading"
          data-bs-target="#confirmation-modal"
          data-bs-toggle="modal"
          size="lg"
          tds-button
          variant="secondary"
        >{{ 'RISK_ASSESSMENT.CLEAR' | translate }}
        </button>
        <button
          (click)="runRiskAssessmentCheck()"
          *ngIf="!riskAssessmentCheckRan"
          [disabled]="verifyCheckButtonEnable() || loading"
          size="lg"
          tds-button
          variant="primary"
        >{{ 'RISK_ASSESSMENT.CHECK_RISK_ASSESSMENT' | translate }}
        </button>
        <button
          (click)="updateConfirmationModalContent('edit')"
          *ngIf="riskAssessmentCheckRan"
          [disabled]="verifyCheckButtonEnable()"
          data-bs-target="#confirmation-modal"
          data-bs-toggle="modal"
          size="lg"
          tds-button
          variant="primary"
        >{{ 'RISK_ASSESSMENT.EDIT' | translate }}
        </button>
        <app-confirmation-modal
          (action)="confirmationModalContent.action()"
          [buttonText]="confirmationModalContent.buttonText"
          [message]="confirmationModalContent.message"
          [title]="confirmationModalContent.title"
        ></app-confirmation-modal>
      </div>
    </div>
  </div>
  <div *ngIf="riskAssessmentCheckRan" class="card w-100 my-3 px-3 py-4" id="complementaries">
    <h4 class="fw-bold m-3 ms-1">{{ 'RISK_ASSESSMENT.COMPLEMENTARY_HAZARDS_CONTROL_MEASURES' | translate }}</h4>
    <div class="row d-flex complementary-container">
      <div *ngIf="complementaryHazards.length > 0" class="col-md-6 col-sm-12 px-3 mb-2">
        <table>
          <tr class="fs-6 fw-bold">
            <th colspan="3">{{ 'RISK_ASSESSMENT.COMPLEMENTARY_HAZARDS_TO_CONSIDER' | translate }}</th>
          </tr>
          <tr *ngFor="let hazard of complementaryHazards">
            <td>{{ hazard.name }}</td>
            <td>
              <tds-icon
                *ngIf="hazard.checked"
                isFilled
                name="check_circle"
                size="sm"
                variant="success"
              />
            </td>
            <td>
              <button (click)="hazardCopied(hazard)" (keydown)="hazardCopied(hazard)" label="Copy"
                      tds-icon-button>
                <tds-icon
                  [variant]="hazardChecked(hazard)"
                  name="content_copy"
                  size="sm"/>
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div *ngIf="complementaryHazards.length === 0" class="col-md-6 col-sm-12">
        <div class="complementaries-not-found">
          <h5 class="mb-4">
            <tds-icon class="me-3" name="error" size="sm" variant="danger"/>
            {{ 'RISK_ASSESSMENT.NO_CONTROL_MEASURE' | translate }}
          </h5>
          <span>{{ 'RISK_ASSESSMENT.CONTROL_MEASURE_ADVICE' | translate }}</span>
        </div>
      </div>
      <div *ngIf="complementaryControlMeasures.length > 0" class="col-md-6 col-sm-12 px-3">
        <table>
          <tr class="fs-6 fw-bold">
            <th colspan="3">{{ 'RISK_ASSESSMENT.COMPLEMENTARY_CONTROL_MEASURES_TO_CONSIDER' | translate }}</th>
          </tr>
          <tr *ngFor="let controlMeasure of complementaryControlMeasures">
            <td>{{ controlMeasure.name }}</td>
            <td>
              <tds-icon
                *ngIf="controlMeasure.checked"
                isFilled
                name="check_circle"
                size="sm"
                variant="success"
              />
            </td>
            <td>
              <button (click)="controlMeasureCopied(controlMeasure)" (keydown)="controlMeasureCopied(controlMeasure)"
                      label="Copy" tds-icon-button>
                <tds-icon
                  [variant]="controlMeasureChecked(controlMeasure)"
                  name="content_copy"
                  size="sm"/>
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div *ngIf="complementaryControlMeasures.length === 0" class="col-md-6 col-sm-12 my-2">
        <div class="complementaries-not-found">
          <h5 class="mb-4">
            <tds-icon class="me-3" name="error" size="sm" variant="danger"/>
            {{ 'RISK_ASSESSMENT.NO_CONTROL_MEASURE' | translate }}
          </h5>
          <span>{{ 'RISK_ASSESSMENT.CONTROL_MEASURE_ADVICE' | translate }}</span>
        </div>
      </div>
    </div>
    <div (click)="displaySources = !displaySources" (keydown)="displaySources = !displaySources"
         class="d-flex justify-content-end m-3">
      <span class="fs-5 cursor-pointer"
            style="color: var(--tds-color-foreground-primary)">{{ 'RISK_ASSESSMENT.SOURCES' | translate }}</span>
      <tds-icon [ngClass]="displaySources? ' rotate-90': ' rotate--90'" name="chevron_left" size="md"
                variant="primary"/>
    </div>
    <div *ngIf="displaySources" class="row d-flex complementary-container mt-2">
      <div *ngIf="hazardsSources.length > 0" class="col-md-6 col-sm-12 my-2">
        <table>
          <tr class="fs-6 fw-bold">
            <th colspan="3">{{'RISK_ASSESSMENT.SOURCES_OF_COMPLEMENTARY_HAZARDS'| translate}}</th>

          </tr>
          <tr *ngFor="let hazard of hazardsSources">
            <td><a [href]="hazard.url" [isExternal]="true" [showVisitedStyle]="false"
                   style="color: var(--tds-color-foreground-secondary)"
                   tds-link
            >{{ hazard.name }}</a></td>
          </tr>
        </table>
      </div>
      <div *ngIf="hazardsSources.length === 0" class="col-md-6 col-sm-12 my-2">
        <div class="complementaries-not-found">
          <h5 class="mb-4">
            <tds-icon class="me-3" name="error" size="sm" variant="danger"/>
            {{ 'RISK_ASSESSMENT.NO_HAZARDS_SOURCE' | translate }}
          </h5>
          <span>{{ 'RISK_ASSESSMENT.HAZARDS_ADVICE' | translate }}</span>
        </div>
      </div>
      <div *ngIf="controlMeasuresSources.length > 0" class="col-md-6 col-sm-12 px-3 my-2">
        <table>
          <tr class="fs-6 fw-bold">
            <th colspan="3">{{'RISK_ASSESSMENT.SOURCES_OF_COMPLEMENTARY_CONTROL_MEASURES'| translate}}</th>
          </tr>
          <tr *ngFor="let cms of controlMeasuresSources">
            <td>
              <a [href]="cms.url" [isExternal]="true" [showVisitedStyle]="false"
                 style="color: var(--tds-color-foreground-secondary)"
                 tds-link>
                {{ cms.name }}</a>
            </td>
          </tr>
        </table>
      </div>
      <div *ngIf="controlMeasuresSources.length === 0" class="col-md-6 col-sm-12 my-2">
        <div class="complementaries-not-found">
          <h5 class="mb-4">
            <tds-icon class="me-3" name="error" size="sm" variant="danger"/>
            {{ 'RISK_ASSESSMENT.NO_CONTROL_MEASURE_SOURCES' | translate }}
          </h5>
          <span>{{ 'RISK_ASSESSMENT.CONTROL_MEASURE_ADVICE' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="riskAssessmentCheckRan" class="card w-100 mb-3 p-4">
    <div class="d-inline-flex justify-content-between">
      <h4 class="fw-bold mb-3">{{'RISK_ASSESSMENT.REX_RELATED_TO_THIS_PERMIT_TO_WORK'| translate}}</h4>
      <div class="d-flex align-items-center justify-content-center">
        <div *ngIf="rexLoader" class="spinner-border mx-3" style="color: var(--tds-color-foreground-primary)">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          (click)="getRex()"
          *ngIf="relatedRex.length === 0"
          size="lg"
          style="margin: 0 var(--tds-size-spacing-200);"
          tds-button
          variant="primary"
        >
          {{'RISK_ASSESSMENT.SHOW_REX'| translate}}
        </button>
      </div>
    </div>

    <div class="row">
      <div *ngFor="let rex of relatedRex"
           class="col-sm-12 col-md-6 d-flex flex-column p-2">
        <div
          style="border:var(--tds-size-border-thin) solid var(--tds-color-border-strong); border-radius: var(--tds-size-radius-150); padding: var(--tds-size-spacing-200) var(--tds-size-spacing-150)">
          <div class="d-inline-flex justify-content-between mb-3 row">
            <div class="w-100 mb-2">
              <a [href]="rex.url" [isExternal]="true" [showVisitedStyle]="false" class="fw-bold"
                 style="color: var(--tds-color-foreground-secondary)" target="_blank" tds-link>
                <u>{{rex.filename}}</u>
                <tds-icon class="pt-1 mx-2" name="open_in_new" size="sm" variant="secondary"/>
              </a>
            </div>
            <div class="d-flex flex-md-row flex-sm-column align-items-start justify-content-start flex-wrap">
              <tds-tag *ngFor="let tag of rex.tags" accent="blue" class="mx-1">{{tag}}</tds-tag>
            </div>
          </div>
          <div>
            {{rex.summary}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

