import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { WebSocketService } from './chatbot.service';
import { ChatbotMessage, DocumentSource, MessageType, SourceType } from '@types';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app-state';
import { addMessage, selectMessages } from './chatbot-state';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  ButtonComponent,
  FieldsetComponent,
  FieldsetLabelDirective,
  FormFieldComponent,
  FormFieldModule,
  FormFieldSuffixDirective,
  IconButtonComponent,
  IconComponent,
  IconVariant,
  LayerComponent,
  LinkComponent,
  RadioComponent,
  TagComponent,
  TypographyComponent
} from '@totalenergiescode/tds-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MarkdownModule } from 'ngx-markdown';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { v4 as uuidV4 } from 'uuid';

type messageDate = {
  date: string;
  question: string;
};

@Component({
  selector: 'app-chatbot',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonComponent,
    FormFieldComponent,
    FormFieldSuffixDirective,
    FormFieldModule,
    TagComponent,
    IconComponent,
    IconButtonComponent,
    MarkdownModule,
    NgbTooltip,
    FormsModule,
    FieldsetLabelDirective,
    RadioComponent,
    LinkComponent,
    FieldsetComponent,
    LayerComponent,
    TypographyComponent
  ],
  providers: [WebSocketService],
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit, AfterViewInit, OnDestroy {
  messages: ChatbotMessage[] = [];
  messages$: Observable<ChatbotMessage[]>;

  public messageToSend: ChatbotMessage = {
    question: '',
    type: MessageType.QUESTION
  };
  displayedMessage: string = '';
  disableSendMsg = false;
  sourceActive?: SourceType = SourceType.HSE;
  robotThinking = false;
  index = 0;
  messageDates: messageDate[] = [];
  isPaused = false;
  timeoutId: NodeJS.Timeout | undefined;
  ignoreMessage = false;
  currentMessageDocument: DocumentSource[] = [];
  currentQuestionId: string = '';
  protected readonly SourceType = SourceType;
  private destroy$ = new Subject<void>();
  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;
  @ViewChild('testMsg') private testMsg!: ElementRef;

  constructor(
    private store: Store<AppState>,
    public chatService: WebSocketService,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.messages$ = this.store.select(selectMessages);
  }

  ngOnInit(): void {
    this.messages$.pipe(takeUntil(this.destroy$)).subscribe((messages) => {
      this.messages = messages;
    });
    this.chatService
      .handleAnswer()
      .pipe(takeUntil(this.destroy$))
      .subscribe((message: ChatbotMessage) => {
        // if (!this.ignoreMessage && message.questionId !== this.currentQuestionId)
        this.revealMessage(message);
      });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  askQuickQuestion(value: string) {
    this.messageToSend.question = this.translate.instant(value);
  }

  public sendMessage() {
    if (
      this.messageToSend.question.trim() === '' ||
      this.disableSendMsg ||
      this.sourceActive === undefined
    )
      return;
    this.robotThinking = true;
    const date = new Date();
    const formattedDate = date.toLocaleString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    const formattedTime = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    this.handleDisplayDateMessage(formattedDate);

    this.currentQuestionId = uuidV4();
    this.messageToSend = {
      question: this.messageToSend.question,
      questionId: this.currentQuestionId,
      date: formattedDate,
      type: MessageType.QUESTION,
      source: this.sourceActive,
      time: formattedTime
    };
    this.chatService.askQuestion(this.messageToSend);
    this.store.dispatch(addMessage({ message: this.messageToSend }));
    this.messageToSend = { question: '', source: undefined, type: MessageType.QUESTION };
    this.scrollToBottom();
    this.testMsg.nativeElement.style.height = '1rem';
    this.testMsg.nativeElement.classList.remove('scroll');

    // Set a timeout for 2 minutes
    this.timeoutId = setTimeout(() => {
      if (this.robotThinking) {
        // If robot is still thinking after 2 minutes, ignore the message
        this.robotThinking = false;
        this.disableSendMsg = false;
        this.displayedMessage = '';
        this.currentMessageDocument = [];
        this.ignoreMessage = true;
      }
    }, 120000);
  }

  revealMessage(messagePart: ChatbotMessage): void {
    clearTimeout(this.timeoutId);
    this.robotThinking = false;
    this.disableSendMsg = true;

    if (messagePart.answer) {
      this.displayedMessage += messagePart.answer;
      if (messagePart.answer.includes('<EOA>')) {
        this.storeMessage();
      }
    }

    if (messagePart.documents?.length) {
      this.currentMessageDocument = messagePart.documents;
    }
  }

  storeMessage() {
    const answer = {
      question: this.messageToSend.question,
      answer: this.displayedMessage,
      type: MessageType.ANSWER,
      documents: this.currentMessageDocument
    };
    this.store.dispatch(addMessage({ message: answer }));
    this.displayedMessage = '';
    this.disableSendMsg = false;
    this.isPaused = false;
    this.currentMessageDocument = [];
  }

  stopGeneration(): void {
    this.storeMessage();
  }

  changeSource(source: string) {
    this.sourceActive = source as SourceType;
    this.sourceActive = source as SourceType;
  }

  autoGrow(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    const lineHeight = parseFloat(getComputedStyle(textarea).lineHeight);
    const maxHeight = lineHeight * 4; // Maximum height corresponds to 4 lines
    const currentRows = textarea.value.split('\n').length;

    if (currentRows <= 4) {
      textarea.style.height = `${currentRows * lineHeight}px`;
    } else {
      textarea.style.height = `${maxHeight}px`;
      textarea.style.overflowY = 'auto'; // Enable scrolling
    }
  }

  sendIconStatus(): IconVariant {
    return this.disableSendMsg || this.sourceActive === undefined ? 'secondary' : 'info';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  displayDate(msg: ChatbotMessage): boolean {
    if (msg.type === 'ANSWER') return false;
    return this.messageDates.some(
      (date) => date.question === msg.question && date.date === msg.date
    );
  }

  handleDisplayDateMessage(formattedDate: string) {
    if (this.messageDates.length === 0) {
      this.messageDates.push({ date: formattedDate, question: this.messageToSend.question });
      return;
    }
    if (this.messageDates[this.index]?.date !== formattedDate) {
      this.messageDates.push({ date: formattedDate, question: this.messageToSend.question });
      this.index++;
    }
  }

  private scrollToBottom() {
    try {
      this.messagesContainer.nativeElement.scrollTop =
        this.messagesContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error('Could not scroll to bottom', err);
    }
  }
}
