<div class="chat-container">
  <div
    *ngIf="messages.length === 0"
    class="d-flex flex-column align-items-center justify-content-center w-100 mt-5"
  >
    <img alt="Smart Toy" src="assets/images/svg/smart-toy.svg">
    <div class="d-flex align-items-center justify-content-center w-messages mt-3 fs-6 row">
      <div
        (click)="askQuickQuestion('CHATBOT.QUESTION_PROMPT_1')"
        (keydown)="askQuickQuestion('CHATBOT.QUESTION_PROMPT_1')"
        class="card border border-1 p-3 col-md-3 col-sm-7 h-question cursor-pointer"
      >
        <tds-icon name="description" size="sm" variant="info"/>
        <span class="mt-1">{{'CHATBOT.QUESTION_PROMPT_1' | translate}}</span>
      </div>
      <div
        (click)="askQuickQuestion('CHATBOT.QUESTION_PROMPT_2')"
        (keydown)="askQuickQuestion('CHATBOT.QUESTION_PROMPT_2')"
        class="card border border-1 my-2 mx-3 p-3 col-md-3 col-sm-7 h-question cursor-pointer"
      >
        <tds-icon name="gavel" size="sm" variant="green"/>
        <span class="mt-1">{{'CHATBOT.QUESTION_PROMPT_2' | translate}}</span>
      </div>
      <div
        (click)="askQuickQuestion('CHATBOT.QUESTION_PROMPT_3')"
        (keydown)="askQuickQuestion('CHATBOT.QUESTION_PROMPT_3')"
        class="card border border-1 p-3 col-md-3 col-sm-7 h-question cursor-pointer"
      >
        <tds-icon name="star" size="sm" variant="yellow"/>
        <span class="mt-1">{{'CHATBOT.QUESTION_PROMPT_3' | translate}}</span>
      </div>
    </div>
  </div>
  <div #messagesContainer
       class="d-flex flex-column-reverse overflow-auto  mb-5 w-messages h-75"
       style="padding: var(--tds--size-spacing-200)">
    <div *ngIf="robotThinking || displayedMessage " class="d-inline-flex">
      <div class="robot-icon">
        <img alt="Smart Toy" src="assets/images/svg/smart-toy.svg" width="25">
      </div>
      <div *ngIf="robotThinking" class="d-flex flex-column message px-4 my-1 received">
        <div class="d-inline-flex mt-2 mb-2">
          <div class="loader__dot bg-dark rounded-circle mt-1 mb-1 p-1"></div>
          <div class="loader__dot bg-dark rounded-circle m-1 p-1"></div>
          <div class="loader__dot bg-dark rounded-circle mt-1 mb-1  p-1"></div>
        </div>
      </div>
      <div *ngIf="displayedMessage" class="message received p-3 my-1">
        <markdown [data]="displayedMessage"></markdown>
      </div>
    </div>

    <div *ngFor="let msg of messages" class="d-flex flex-column">
      <div
        *ngIf="displayDate(msg)"
        class="d-flex justify-content-center align-items-center mb-3">
        <hr class="flex-grow-1 pe-5" style="color: var(--tds-color-border-hover)">
        <span class="px-2" style="color: var(--tds-color-foreground-secondary)">{{ msg.date }}</span>
        <hr class="flex-grow-1 ps-5" style="color: var(--tds-color-border-hover)">
      </div>
      <!-- Message de type QUESTION -->
      <div *ngIf="msg.type === 'QUESTION'" class="message p-3 my-1 sent">
        {{ msg.question }}
      </div>
      <div *ngIf="msg.date" class="d-flex justify-content-end mt-2">
        <span style="color: var(--tds-color-foreground-secondary)">{{ msg.time }}</span>
      </div>
      <!-- Message de type ANSWER -->
      <div *ngIf="msg.type === 'ANSWER'" class="d-inline-flex">
        <div class="robot-icon">
          <img alt="Smart Toy" src="assets/images/svg/smart-toy.svg" width="25">
        </div>
        <div *ngIf="msg.type === 'ANSWER'" class="d-flex flex-column message p-3 my-1 received">
          <markdown [data]="msg.answer"></markdown>
          <span *ngIf="msg.documents && msg.documents.length > 0" class="mt-2 fw-bold">
        {{'CHATBOT.SOURCE_LABEL' | translate}}
      </span>
          <ul *ngIf="msg.documents && msg.documents.length > 0" class="my-2">
            <li *ngFor="let source of msg.documents" class="my-1">
              <a [href]="source.url" [isExternal]="true" [showVisitedStyle]="false"
                 style="color: var(--tds-color-foreground-secondary)" target="_blank" tds-link>
                {{ source.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="displayedMessage" class="d-flex align-items-center justify-content-center w-100 mb-3">
    <button (click)="togglePause()" size="md" tds-button variant="secondary">
      {{ isPaused ? 'Continue' : 'Stop generation' }}
    </button>
  </div>
  <div class="d-inline-flex justify-content-end w-messages mb-2">
    <fieldset orientation="horizontal" tds-fieldset>
      <tds-fieldset-label>{{'CHATBOT.SOURCE' | translate}}</tds-fieldset-label>
      <tds-radio>
        <input (change)="changeSource('HSE')" [checked]="this.sourceActive === SourceType.HSE" name="hse"
               tds-radio-input type="radio" value="HSE"/>
        <span tds-radio-label>HSE</span>
      </tds-radio>
      <tds-radio (change)="changeSource('REX')">
        <input [checked]="this.sourceActive === SourceType.REX" name="rex" tds-radio-input type="radio" value="REX"/>
        <span tds-radio-label>REX</span>
      </tds-radio>
    </fieldset>
  </div>
  <div class="w-messages">
    <tds-form-field>
     <textarea
       #testMsg
       (input)="autoGrow($event)"
       (keydown.enter)="sendMessage(); $event.preventDefault()"
       [(ngModel)]="messageToSend.question"
       class="w-100"
       data-hj-allow
       placeholder="{{'CHATBOT.QUESTION_PLACEHOLDER' | translate}}"
       rows="1"
       style="height: 1.3rem;"
       tds-form-field-item
     ></textarea>
      <tds-form-field-suffix>
        <button (click)="sendMessage()" label="Send message" size="lg"
                tds-icon-button>
          <tds-icon name="send"></tds-icon>
        </button>
      </tds-form-field-suffix>
    </tds-form-field>
  </div>
  <div class="w-messages"
       style="color: var(--tds-color-foreground-secondary); margin: var(--tds-size-spacing-200) 0; padding: 0 var(--tds-size-spacing-100)">
    {{'CHATBOT.CONTEXT_PROMPT' | translate}}
  </div>
</div>
