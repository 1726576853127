export interface ChatbotMessage {
  date?: string;
  time?: string;
  userId?: string;
  sessionId?: string;
  questionId?: string;
  type: MessageType;
  // Question
  question: string;
  source?: SourceType;
  // Answer
  answer?: string;
  documents?: DocumentSource[];
}

export enum SourceType {
  HSE = 'HSE',
  REX = 'REX'
}

export enum MessageType {
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER'
}

export interface DocumentSource {
  name: string;
  url: string;
}

export enum language {
  FR = 'fr',
  EN = 'en'
}
