import {createAction, createFeatureSelector, createReducer, createSelector, on, props} from '@ngrx/store';
import {CheckRiskAssessment, ControlMeasure, Hazard, RelatedRex, Source} from '@types';

export interface RiskAssessmentCheckState {
  checkRiskAssessment: CheckRiskAssessment | null;
  complementaryHazards: Hazard[];
  hazardsSources: Source[];
  complementaryControlMeasures: ControlMeasure[];
  controlMeasuresSources: Source[];
  relatedRex: RelatedRex[];
  riskAssessmentCheckRan: boolean;
  file: File;
  fileStatus: string;
}

export const initialRiskAssessmentCheckState: RiskAssessmentCheckState = {
  checkRiskAssessment: null,
  complementaryHazards: [],
  hazardsSources: [],
  complementaryControlMeasures: [],
  controlMeasuresSources: [],
  relatedRex: [],
  riskAssessmentCheckRan: false,
  file: new File([], ''),
  fileStatus: '',
};

export const setFileStatus = createAction(
  'setFileStatus',
  props<{ fileStatus: string }>()
);

export const setFile = createAction(
  'setFile',
  props<{ file: File }>()
);

export const setCheckRiskAssessment = createAction(
  'setCheckRiskAssessment',
  props<{ checkRiskAssessment: CheckRiskAssessment }>()
);

export const setComplementaryHazards = createAction(
  'setComplementaryHazards',
  props<{ complementaryHazards: Hazard[] }>()
);

export const setComplementaryControlMeasures = createAction(
  'setComplementaryControlMeasures',
  props<{ complementaryControlMeasures: ControlMeasure[] }>()
);

export const setRelatedRex = createAction(
  'setRelatedRex',
  props<{ relatedRex: RelatedRex[] }>()
);

export const setRiskAssessmentCheckRan = createAction(
  'setRiskAssessmentCheckRan',
  props<{ riskAssessmentCheckRan: boolean }>()
);

export const updateHazard = createAction(
  'updateHazard',
  props<{ hazard: Hazard }>()
);

export const updateControlMeasure = createAction(
  'updateControlMeasure',
  props<{ controlMeasure: ControlMeasure }>()
);

export const setHazardsSources = createAction(
  'setHazardsSources',
  props<{ hazardsSources: Source[] }>()
);

export const setControlMeasuresSources = createAction(
  'setControlMeasuresSources',
  props<{ controlMeasuresSources: Source[] }>()
);

export const riskAssessmentCheckReducer = createReducer(
  initialRiskAssessmentCheckState,
  on(setCheckRiskAssessment, (state, {checkRiskAssessment}) => ({...state, checkRiskAssessment})),
  on(setComplementaryHazards, (state, {complementaryHazards}) => ({...state, complementaryHazards})),
  on(setRiskAssessmentCheckRan, (state, {riskAssessmentCheckRan}) => ({...state, riskAssessmentCheckRan})),
  on(setComplementaryControlMeasures, (state, {complementaryControlMeasures}) => ({
    ...state,
    complementaryControlMeasures
  })),
  on(setRelatedRex, (state, {relatedRex}) => ({...state, relatedRex})),
  on(updateHazard, (state, {hazard}) => {
    const updatedHazards = state.complementaryHazards.map(h => {
      if (h.name === hazard.name) {
        return {...h, checked: true};
      }
      return h;
    });
    return {...state, complementaryHazards: updatedHazards};
  }),
  on(updateControlMeasure, (state, {controlMeasure}) => {
    const updatedControlMeasure = state.complementaryControlMeasures.map(cm => {
      if (cm.name === controlMeasure.name) {
        return {...cm, checked: true};
      }
      return cm;
    });
    return {...state, complementaryControlMeasures: updatedControlMeasure};
  }),
  on(setFile, (state, {file}) => ({...state, file})),
  on(setFileStatus, (state, {fileStatus}) => ({...state, fileStatus})),
  on(setHazardsSources, (state, {hazardsSources}) => ({...state, hazardsSources})),
  on(setControlMeasuresSources, (state, {controlMeasuresSources}) => ({...state, controlMeasuresSources}))
);


export const selectRiskAssessmentCheckState = createFeatureSelector<RiskAssessmentCheckState>('riskAssessment');

export const selectCheckRiskAssessment = createSelector(
  selectRiskAssessmentCheckState,
  (state: RiskAssessmentCheckState) => state.checkRiskAssessment
);

export const selectComplementaryHazards = createSelector(
  selectRiskAssessmentCheckState,
  (state: RiskAssessmentCheckState) => state.complementaryHazards
);

export const selectComplementaryControlMeasures = createSelector(
  selectRiskAssessmentCheckState,
  (state: RiskAssessmentCheckState) => state.complementaryControlMeasures
);

export const selectRelatedRex = createSelector(
  selectRiskAssessmentCheckState,
  (state: RiskAssessmentCheckState) => state.relatedRex
);

export const selectRiskAssessmentCheckRan = createSelector(
  selectRiskAssessmentCheckState,
  (state: RiskAssessmentCheckState) => state.riskAssessmentCheckRan
);

export const selectFile = createSelector(
  selectRiskAssessmentCheckState,
  (state: RiskAssessmentCheckState) => state.file
);

export const selectFileStatus = createSelector(
  selectRiskAssessmentCheckState,
  (state: RiskAssessmentCheckState) => state.fileStatus
);

export const selectHazardsSources = createSelector(
  selectRiskAssessmentCheckState,
  (state: RiskAssessmentCheckState) => state.hazardsSources
);

export const selectControlMeasuresSources = createSelector(
  selectRiskAssessmentCheckState,
  (state: RiskAssessmentCheckState) => state.controlMeasuresSources
);

