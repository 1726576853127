import { Component, Input } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgClass } from '@angular/common';
import { IconComponent, IconSize } from '@totalenergiescode/tds-angular';

@Component({
  selector: 'app-tooltip-info',
  standalone: true,
  imports: [NgbTooltipModule, NgClass, IconComponent],
  templateUrl: './tooltip-info.component.html',
  styleUrl: './tooltip-info.component.scss'
})
export class TooltipInfoComponent {
  @Input() message: string = '';
  @Input() size: IconSize = 'sm';
}
