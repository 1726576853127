import {ChatbotMessage} from '@types';
import {createAction, createFeatureSelector, createReducer, createSelector, on, props} from '@ngrx/store';

export interface ChatbotState {
  messages: ChatbotMessage[];
}

export const initialChatState: ChatbotState = {
  messages: []
};

export const addMessage = createAction(
  'addMessage',
  props<{ message: ChatbotMessage }>()
);

export const chatbotReducer = createReducer(
  initialChatState,
  on(addMessage, (state, {message}) => ({...state, messages: [message, ...state.messages]}))
);

export const selectChatbotState = createFeatureSelector<ChatbotState>('chatbot');

export const selectMessages = createSelector(
  selectChatbotState,
  (state: ChatbotState) => state.messages
);
