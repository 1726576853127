import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MsalGuard} from '@azure/msal-angular';
import {RiskAssessmentCheckComponent} from './components/risk-assessment-check/risk-assessment-check.component';
import {ChatbotComponent} from './components/chatbot/chatbot.component';

const routes: Routes = [
  {
    path: '',
    component: RiskAssessmentCheckComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'chatbot',
    component: ChatbotComponent,
    canActivate: [MsalGuard]
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
