import { Component } from '@angular/core';
import { AuthenticationService } from '../../security/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { language } from '@types';

const RISK_ASSESSMENT_CHECK_TAB = 'risk-assessment-check';
const CHATBOT_TAB = 'chatbot';
const DATA_SOURCE_MANAGEMENT_TAB = 'data-source-management';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  public connectedUser = this.authenticationService.connectedUser;

  availableTabs = [RISK_ASSESSMENT_CHECK_TAB, CHATBOT_TAB, DATA_SOURCE_MANAGEMENT_TAB];

  selectedTab =
    this.availableTabs.find((tab) => window.location.href.endsWith(tab)) ??
    RISK_ASSESSMENT_CHECK_TAB;

  currentLang: string;
  isAdmin = false;
  protected readonly language = language;

  constructor(
    private authenticationService: AuthenticationService,
    private translateService: TranslateService
  ) {
    const savedLanguage = localStorage.getItem('language') ?? language.EN;
    this.translateService.use(savedLanguage);
    this.currentLang = savedLanguage;
    this.isAdmin = this.authenticationService.isAdmin();
  }

  public logout() {
    this.authenticationService.logout();
  }

  onChangeTab(tab: string): void {
    this.selectedTab = tab;
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
    localStorage.setItem('language', language);
    this.currentLang = language;
  }
}
