import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonComponent, IconButtonComponent, IconComponent} from '@totalenergiescode/tds-angular';


@Component({
  selector: 'app-upload-pdf-modal',
  standalone: true,
  imports: [
    ButtonComponent,
    IconComponent,
    IconButtonComponent,
  ],
  templateUrl: './upload-pdf-modal.component.html',
})
export class UploadPdfModalComponent {
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() buttonActionText: string = '';
  @Input() buttonCancelText: string = '';
  @Output() action = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<MouseEvent>();

  cancelAction(event: MouseEvent) {
    if (this.title === 'Invalid PDF') this.cancel.emit(event);
  }

}
