<nav class="navbar navbar-expand-lg">
  <!-- Logo and Product Name -->
  <div class="container-fluid d-flex align-items-center align-content-between row">
    <div class="col-md-2 col-sm-12 d-flex align-items-center">
      <a class="navbar-brand" href="#">
        <img alt="Smart Toy" src="assets/images/svg/logo.svg" />
      </a>
      <span class="navbar-app-name">Risk Advisor</span>
    </div>
    <div class="col-md-6 col-sm-6 nav-tabs d-flex align-items-center">
      <div class="nav-item px-2">
        <a
          (click)="onChangeTab(availableTabs[0])"
          [ngClass]="{ active: selectedTab === availableTabs[0] }"
          class="nav-link"
          routerLink="/"
          >Risk Assessment</a
        >
      </div>
      <div class="nav-item px-2">
        <a
          (click)="onChangeTab(availableTabs[1])"
          [ngClass]="{ active: selectedTab === availableTabs[1] }"
          class="nav-link"
          routerLink="/chatbot"
          >Chatbot</a
        >
      </div>
      <div *ngIf="isAdmin" class="nav-item px-2">
        <a
          (click)="onChangeTab(availableTabs[2])"
          [ngClass]="{ active: selectedTab === availableTabs[2] }"
          class="nav-link"
          routerLink="/data-source-management"
          >Data Source Management</a
        >
      </div>
    </div>
    <!-- User Profile -->
    <div class="col-md-4 col-sm-6 d-flex align-items-center justify-content-end">
      <div class="dropdown d-flex justify-content-center align-items-center">
        <button
          aria-expanded="false"
          class="mx-2"
          data-bs-toggle="dropdown"
          id="profile"
          label="Profile"
          tds-icon-button
        >
          <tds-icon name="account_circle" size="md"></tds-icon>
        </button>
        <ul aria-labelledby="profile" class="dropdown-menu dropdown-menu-end">
          <li>
            <span class="navbar-text text-uppercase fw-bold m-3" data-hj-suppress>{{
              (connectedUser | async)?.name
            }}</span>
          </li>
          <li>
            <hr />
          </li>
          <li>
            <div
              (click)="logout()"
              (keydown)="logout()"
              class="dropdown-item d-flex align-items-center"
            >
              <tds-icon name="logout" size="md"></tds-icon>
              <span class="ms-2">Logout</span>
            </div>
          </li>
        </ul>
      </div>
      <!-- Language Selector -->
      <div class="dropdown ms-2">
        <button
          aria-expanded="false"
          class="btn dropdown-toggle"
          data-bs-toggle="dropdown"
          id="dropdownMenuButton2"
          type="button"
        >
          {{ currentLang | uppercase }}
        </button>
        <ul aria-labelledby="dropdownMenuButton2" class="dropdown-menu">
          <li><a (click)="switchLanguage(language.EN)" class="dropdown-item">English</a></li>
          <li><a (click)="switchLanguage(language.FR)" class="dropdown-item">French</a></li>
        </ul>
      </div>
      <div class="navbar-brand d-inline-flex mb-1">
        <img alt="Logo TotalEnergies" src="../../../assets/images/te_logo.png" />
      </div>
    </div>
  </div>
</nav>
