<div aria-hidden="true" class="modal fade" id="uploadPDFModal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-inline-flex align-items-start">
        <div class="modal-title" id="editConfirmationModalLabel">
          {{title}}
        </div>
        <button data-bs-dismiss="modal" label="Close" size="lg" tds-icon-button>
          <tds-icon name="close"></tds-icon>
        </button>
      </div>
      <div class="modal-body">
        {{message}}
      </div>
      <div class="modal-footer">
        <button (click)="cancelAction($event)" aria-label="Cancel" data-bs-dismiss="modal"
                size="md"
                tds-button
                type="button"
                variant="secondary">
          {{buttonCancelText}}
        </button>
        <button
          (click)="action.emit()"
          aria-label="Primary Action"
          data-bs-dismiss="modal"
          size="md"
          tds-button
          type="button"
          variant="primary"
        >
          {{buttonActionText}}
        </button>
      </div>
    </div>
  </div>
</div>
