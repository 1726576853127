import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, map} from 'rxjs';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';

type Id = number | string;

@Injectable({
  providedIn: 'root'
})
export class HttpService<T> {
  baseUrl = `${environment.backend_url}${environment.baseUrl}`;

  constructor(
    private http: HttpClient,
  ) {
  }

  get(id: Id, url: string): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}${url}/${id}`);
  }

  post<V>(item: T, url: string): Observable<V> {
    return this.http.post<V>(`${this.baseUrl}${url}`, item);
  }

  uploadFile(url: string, file: File): Observable<T> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data'
    });
    return this.http
      .post<T>(`${this.baseUrl}${url}`, formData, {headers: headers, observe: 'response'})
      .pipe(map((response) => response.body as T));
  }
}
