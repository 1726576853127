import {Injectable} from '@angular/core';
import {io} from 'socket.io-client';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../../../app/security/authentication.service';
import {ChatbotMessage} from '@types';
import {v4 as uuidV4} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket;
  private connectedUser = this.authenticationService.connectedUser;

  constructor(private authenticationService: AuthenticationService) {
    this.socket = io(`${environment.webSocketServerUrl}`, {
      reconnectionAttempts: 3
    });
  }

  askQuestion(message: ChatbotMessage) {
    this.socket.emit('question', {
      ...message,
      sessionId: this.socket.id,
      userId: this.connectedUser.value?.userId,
      questionId: uuidV4()
    });
  }

  handleAnswer(): Observable<ChatbotMessage> {
    return new Observable((observer) => {
      this.socket.on('answer', (data) => {
        observer.next(data);
      });
    });
  }
}
