<tds-form-field [hasError]="showError">
  <tds-form-field-label>{{ label }}
    <app-tooltip-info *ngIf="infoMessage" [message]="infoMessage" class="mx-1" size="xs"></app-tooltip-info>
  </tds-form-field-label>
  <div class="d-flex justify-content-between w-100" tds-form-field-item>
    <div class="d-flex align-items-center justify-content-center">
      <tds-tag (close)="removeBadge(badge)" *ngFor="let badge of badges" accent="blue" class="mx-1"
               isClosable>{{badge}}</tds-tag>
      <input
        #inputElement
        (blur)="handleBlur()"
        (focusout)="onFocusOut($event)"
        (input)="updateValue($event)"
        (keydown)="onKeyDown($event)"
        [disabled]="disabled"
        [id]="id"
        [placeholder]="placeholder"
        [required]="required"
        [value]="value"
        class="w-100 px-2"
        data-hj-allow
        style="outline: none; border: none;"
        type="text"
      />
    </div>
    <tds-icon *ngIf="showError" class="mx-2" isFilled name="error" size="sm" variant="danger"></tds-icon>
  </div>


</tds-form-field>

