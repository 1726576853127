import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hj: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _hjSettings: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {
    const angularPlugin = new AngularPlugin();
    const clickPluginInstance = new ClickAnalyticsPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsInstrumentationKey, //
        enableCorsCorrelation: true,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
          [clickPluginInstance.identifier]: { autoCapture: true }
        }
      }
    });
    appInsights.loadAppInsights();
  }
  ngOnInit(): void {
    this.setupHotjar();
  }

  setupHotjar(): void {
    window.hj =
      window.hj ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (window.hj.q = window.hj.q || []).push(arguments);
      };
    window._hjSettings = { hjid: `${environment.hotjarId}`, hjsv: 6 };

    const scr = document.createElement('script');
    scr.async = Boolean(1);
    scr.src =
      'https://static.hotjar.com/c/hotjar-' +
      window._hjSettings.hjid +
      '.js?sv=' +
      window._hjSettings.hjsv;

    document.head.appendChild(scr);
  }
}
