import {Component} from '@angular/core';
import {AuthenticationService} from '../../security/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {language} from '@types';

const CHATBOT_TAB = 'chatbot';
const RISK_ASSESSMENT_CHECK_TAB = 'risk-assessment-check';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  public connectedUser = this.authenticationService.connectedUser;

  availableTabs = [RISK_ASSESSMENT_CHECK_TAB, CHATBOT_TAB];

  selectedTab = window.location.href.endsWith(CHATBOT_TAB)
    ? CHATBOT_TAB
    : RISK_ASSESSMENT_CHECK_TAB;

  currentLang: string;
  protected readonly language = language;

  constructor(
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
  ) {
    const savedLanguage = localStorage.getItem('language') ?? language.EN;
    this.translateService.use(savedLanguage);
    this.currentLang = savedLanguage;
  }

  public logout() {
    this.authenticationService.logout();
  }

  onChangeTab(tab: string): void {
    this.selectedTab = tab;
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
    localStorage.setItem('language', language);
    this.currentLang = language;
  }
}
