<nav class="navbar navbar-expand-sm flex-column shadow-sm">
  <div class="container-fluid d-flex w-100 header-with-tabs-for-desktop">
    <!-- TotalEnergies Logo -->
    <div class="w-100">
      <div class="container-fluid d-flex">
        <!-- Application Name -->
        <span class="navbar-app-name d-flex align-items-center">Risk Advisor</span>
        <!-- Navbar content -->
        <ul class="nav nav-tabs ms-4">
          <li class="nav-item">
            <button
              (click)="onChangeTab(availableTabs[0])"
              [ngClass]="{ active: selectedTab === availableTabs[0] }"
              class="nav-link"
              routerLink="/"
              type="button"
            >
              Risk Assessment
            </button>
          </li>
          <li class="nav-item">
            <button
              (click)="onChangeTab(availableTabs[1])"
              [ngClass]="{ active: selectedTab === availableTabs[1] }"
              class="nav-link"
              routerLink="/chatbot"
              type="button"
            >
              Chatbot
            </button>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto d-flex flex-row">
          <li class="nav-item">
            <div class="dropdown d-flex justify-content-center align-items-center">
              <span class="navbar-text text-uppercase" data-hj-suppress>{{
                (connectedUser | async)?.name
                }}</span>
              <button
                aria-expanded="false"
                class="mx-2"
                data-bs-toggle="dropdown"
                id="profile"
                label="Profile"
                tds-icon-button
              >
                <tds-icon name="account_circle" size="md"></tds-icon>
              </button>
              <ul aria-labelledby="profile" class="dropdown-menu dropdown-menu-end">
                <li><a (click)="logout()" class="dropdown-item" href="#">Logout</a></li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <div class="dropdown">
              <button
                aria-expanded="false"
                class="btn dropdown-toggle"
                data-bs-toggle="dropdown"
                id="lang"
                type="button"
              >
                {{ currentLang | uppercase }}
              </button>
              <ul
                aria-labelledby="lang"
                class="dropdown-menu dropdown-menu-end"
              >
                <li>
                  <a
                    (click)="switchLanguage(language.EN)"
                    class="dropdown-item"
                  >English</a
                  >
                </li>
                <li>
                  <a
                    (click)="switchLanguage(language.FR)"
                    class="dropdown-item"
                  >French</a
                  >
                </li>

              </ul>
            </div>
          </li>
        </ul>
        <div class="navbar-brand d-inline-flex">
          <img alt="Logo TotalEnergies" src="../../../assets/images/te_logo.png"/>
        </div>
      </div>
    </div>
  </div>
</nav>
