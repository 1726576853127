import {EnvironmentInterface} from './environment-interface';

export const environment: EnvironmentInterface = {
  backend_url: 'https://azwbd-riskadvisor-01.azurewebsites.net',
  authentication: {
    clientId: 'ea235e0d-77ed-4e80-a5b1-5058414e63ee',
    authority: 'https://login.microsoftonline.com/329e91b0-e21f-48fb-a071-456717ecc28e',
    scopes: ['api://6c257350-e50c-45a3-9e99-46061be85ed1/User.Read'],
    redirectUri: '/'
  },
  baseUrl: '/api',
  webSocketServerUrl: 'wss://azwbd-riskadvisor-01.azurewebsites.net/chatbot',
  hotjarId: 5095034
};
